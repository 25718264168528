import { render, staticRenderFns } from "./architecMore.vue?vue&type=template&id=9e860a24&scoped=true"
import script from "./architecMore.vue?vue&type=script&lang=js"
export * from "./architecMore.vue?vue&type=script&lang=js"
import style0 from "./architecMore.vue?vue&type=style&index=0&id=9e860a24&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9e860a24",
  null
  
)

export default component.exports